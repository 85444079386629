<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Carica documento {{documentName}}
    </template>
    <template #body>
      <vue-dropzone
        v-if="isDoc"
        ref="imgDropZone"
        :id="dropZoneId"
        :options="dropzoneOptions"
        @vdropzone-success="afterSuccess"
        @vdropzone-complete="afterComplete"
      ></vue-dropzone>
    </template>
  </modal-wrapper>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { createUriUpload, extensionAttach, mimeTypeAttach } from '@/utils/documents';
import TokenService from '@/services/token.service';
import { isNotEmpty } from '@/utils/validators';
import { DOCUMENT_CLASS } from '@/utils/interfaces';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalDocumentUpload',
  props: {
    id: String,
    entityType: String,
    entityId: String,
    documentName: String,
    document: {
      ...DOCUMENT_CLASS,
    },
    onSuccess: Function,
  },
  components: { ModalWrapper, vueDropzone: vue2Dropzone },
  computed: {
    dropZoneId() {
      return `${this.id}_dropzone`;
    },
    isDoc() {
      return isNotEmpty(this.document);
    },
    dropzoneOptions() {
      return {
        url: createUriUpload(this.document, this.entityType, this.entityId),
        thumbnailWidth: 350,
        thumbnailHeight: 100,
        addRemoveLinks: false,
        acceptedFiles: mimeTypeAttach.join(),
        headers: TokenService.authHeader(),
        dictDefaultMessage: `<p class='text-default'>
                                <font-awesome-icon icon="cloud-upload-alt"></font-awesome-icon>
                                 Trascina un documento o fai click in quest'area
                             </p>
          <p class="form-text">File accettati: ${extensionAttach.join()}</p>
          `,
      };
    },
  },
  methods: {
    //         v-on:vdropzone-sending="sendingEvent"
    // sendingEvent(file, xhr, formData) {
    //   //formData.append('paramName', 'some value or other');
    // },
    async afterSuccess(file, response) {
      console.log('response', response);
      this.$store.dispatch('documents/setDocument', {
        entityClass: this.entityType,
        entityId: this.entityId,
        doc: response.data,
      });
      if (isNotEmpty(this.onSuccess)) {
        this.onSuccess(response.data);
      }
    },
    async afterComplete() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
